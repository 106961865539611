import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Carousel3d from 'vue-carousel-3d'
import i18n from './lang'

import './assets/main.css'
import VueAnalytics from 'vue-analytics'
import cocosUtil from './assets/cocosUtil.js'
Vue.prototype.$cocosUtil = cocosUtil
// import 'normalize.css/normalize.css'

Vue.use(VueAnalytics, {
  id: 'UA-211701570-1',
  router,
  autoTracking: {
    // pageviewOnLoad: false
    pageviewTemplate (route) {
      return {
        page: route.path,
        title: 'pokerglory',
        location: window.location.href
      }
    }
  },
  commands: {
    trackName (name = 'unknown') {
      this.$ga.event({
        eventCategory: 'Downloads',
        eventAction: 'download'
      })
    }
  }
})
Vue.use(Carousel3d)

// gsap.registerPlugin(ScrollTrigger);


Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
