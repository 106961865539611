<template>
  <v-row class="text-center">
    <v-col cols="12" class="section-01" ref="">
      <div class="bg-block">
        <img class="bg1" src="/static/img/home/bg1.png" alt="" srcset="" />
        <img class="bottom" src="/static/img/home/wall.png" alt="" srcset="" />
        <img class="cloud" src="/static/img/home/cloud-03.png" alt="" srcset="" />
        <img class="king" src="/static/img/home/king.png"/>
        <img class="bottom blue-line" src="/static/img/home/bg1-1.png" alt="" srcset="" />
      </div>
      <div class="content">
        <div class="detail">
          <img class="slogan" src="/static/img/home/slogan.png"/>
          <p>
            Welcome to the glorious kingdom of Poker Glory!
            Compete with global poker masters on the same stage, fight alongside Clan members, and conquer on your way to ultimate glory!
          </p>
          <div class="download" style="text-align:center" >
            <!-- <a href="https://play.google.com/store/apps/details?id=com.nuts.pokerglory.android" target="_blank"><img @click="googleDownload()" src="/static/img/common/google.png" /></a> -->
             <a href="https://apps.apple.com/us/app/poker-glory-texas-holdem/id1570964814" target="_blank"><img @click="appleDownload()" src="/static/img/common/apple.png" /></a>
             <a :href="ANDROID_VERSON"><img @click="androidDownload()" src="/static/img/common/android.png" /></a>
          </div>
        </div>
      </div>
    </v-col>

    <v-col cols="12" class="section-02">
      <v-container>
        <h1>CLASSIC TEXAS HOLD'EM</h1>
        <p class="content-1 mb-0 "> Feel the excitement of real-time poker! &nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p class="content">Enjoy the seamless gaming experience!</p>
        <div class="scroll-block">
          <img
            v-show="showAnimation1"
            class="scrollimgzoom"
            src="/static/img/home/ClassicTexasHold’em.png"
          />
        </div>
      </v-container>

    </v-col>

    <v-col cols="12" class="section-03">
      <img class="underline" src="/static/img/home/bule.png" />
      <v-container>
        <div class="detail">
          <p class="titleName">POWERUP</p>
          <p class="subtitle content-1">Activiate special Powers for a unique poker experience! &nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p class="subtitle content">Even more fun with boosted protection and winnings!</p>
        </div>
        <img v-show="showAnimation2" class="gif" src="/static/img/home/gif.png" />
      </v-container>
    </v-col>

    <v-col
      class="section-04"
      cols="12"
    >
      <v-container>
        <div class="detail">
          <p class="titleName">RANKED SIT&GO'S</p>
          <p class="subtitle content-1">An immersive and intense competition to collect Stars!&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p class="subtitle content">Conquer the challenges and become a PokerGlory Legend!</p>
        </div>
        <img v-show="showAnimation3" src="/static/img/home/Sit&Go’s.png" />
      </v-container>
    </v-col>

    <v-col
      class="section-05"
      cols="12"
    >
      <v-container>
        <div class="detail">
          <p class="titleName">CLAN FEATURE</p>
          <p class="subtitle content-1">Join a Clan to battle & gain honor alongside friends!&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p class="subtitle content">With generous rewards awaiting you every day!</p>
        </div>
        <img v-show="showAnimation4" class="clan" src="/static/img/home/clan.png" />
      </v-container>
      <img v-show="showAnimation5" class="underline" src="/static/img/home/bule.png" />
    </v-col>

    <v-col
      class="section-06"
      cols="12"
    >

      <v-container>
        <div class="detail">
          <p class="titleName">MULTI-LANGUAGE SUPPORT</p>
          <p class="subtitle content-1">Intelligent in-game chat with translation support!&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p class="subtitle content">Express your thoughts without language barriers!</p>
        </div>
        <div class="d-flex justify-center">
          <img v-show="showAnimation6" class="multi" src="/static/img/home/Multi.png" />
        </div>
      </v-container>
      <v-container v-show="showAnimation7">
        <p class="carouse-title">— MORE THAN POKER —</p>
        <div  class="carousel">
          <carousel-3d
            style="overflow: inherit;"
            v-show="showAnimation7"
            :perspective="0"
            :space="340"
            :display="3"
            :border="0"
            :width="380"
            :height="550"
            :autoplay="true"
            :autoplay-timeout="3000"
            :animation-speed="600"
            :controls-visible="true"
            :controls-prev-html="prevBtn"
            :controls-next-html="nextBtn"
          >
            <slide
              v-for="(item, i) in banner"
              :index="i"
              :key="i"
              class="slide"
              style="overflow: inherit;"
            >
              <img :src="item.image"/>
            </slide>
          </carousel-3d>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'home',
  data(){
    return {
      showAnimation1: false,
      showAnimation2: false,
      showAnimation3: false,
      showAnimation4: false,
      showAnimation5: false,
      showAnimation6: false,
      showAnimation7: false,
      scroll: 0,
      prevBtn: '<img src="/static/img/home/arrow_pre.png">',
      nextBtn: '<img src="/static/img/home/arrow_next.png">',
      banner:[
        {
          image:'/static/img/home/3d-carousel/6-1.png'
        },
        {
          image:'/static/img/home/3d-carousel/6-2.png'
        },
        {
          image:'/static/img/home/3d-carousel/6-3.png'
        }
      ],
      ANDROID_VERSON: ''
    }
  },
  methods:{
    googleDownload(){
      this.$ga.event({
        eventCategory: 'Downloads',
        eventAction: 'download',
        eventLabel: 'google'
      })
    },
    appleDownload(){
      this.$ga.event({
        eventCategory: 'Downloads',
        eventAction: 'download',
        eventLabel: 'apple'
      })
    },
    androidDownload(){
      this.$ga.event({
        eventCategory: 'Downloads',
        eventAction: 'download',
        eventLabel: 'android'
      })
    }
  },
  created () {
    this.ANDROID_VERSON = ANDROID_VERSON;
  },
  mounted() {

    document
    .querySelector("#app")
    .scrollIntoView({ block: "start", behavior: "smooth" });
    if(screen.width > 450) {
      window.addEventListener('scroll', () => {
        let scrollt =
          document.documentElement.scrollTop || document.body.scrollTop
        if (scrollt > 500) {
          this.showAnimation1 = true
        }
        if (scrollt > 1300) {
          this.showAnimation2 = true
        }
        if (scrollt > 2300) {
          this.showAnimation3 = true
        }
        if (scrollt > 3000) {
          this.showAnimation4 = true
          this.showAnimation5 = true
        }
        if (scrollt > 4000) {
          this.showAnimation6 = true
          this.showAnimation7 = true
        }
      })
    } else {
      document.getElementById("app").onscroll = () => {
        let scrollt = document.getElementById('app').scrollTop
        if (scrollt > 300) {
          this.showAnimation1 = true
        }
        if (scrollt > 800) {
          this.showAnimation2 = true
        }
        if (scrollt > 1500) {
          this.showAnimation3 = true
        }
        if (scrollt > 2000) {
          this.showAnimation4 = true
          this.showAnimation5 = true
        }
        if (scrollt > 2600) {
          this.showAnimation6 = true
          this.showAnimation7 = true
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.scroll-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes scaleDraw {
  from {
    transform:scale(0.7);
    opacity: 0.5;
  }
  to {
    transform:scale(1);
    opacity: 1;

  }
}
.section-01 {
  padding: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: 70px;

  .bg-block {
    clip-path: polygon(0% 0%, 100% 0%, 100% 89%, 50% 100%, 0% 89%);
    min-width: 1000px;
    width: 100%;
    position: relative;

    img {
      width: 100%;

      &.bottom {
        position: absolute;
        left: 0;
        bottom: 0;
      }
      &.cloud{
        position: absolute;
        left: 0;
        bottom: 0;
      }

      &.king {
        position: absolute;
        left: 15%;
        top: 20%;
        width: 30%;

        @media only screen and (max-width: 980px) {
          top: 40%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

  }
  .content{
    position: absolute;
    display: flex;
    justify-content: center;
    left: 70px;
    top: 250px;
    width: 100%;
    z-index: 0;

    .detail{
      margin-left: 300px;
      text-align: left;
      max-width: 600px;
      width: 100%;

      .slogan {
        width: 100%;
      }

      p {
        margin-top: 20px;
        max-width: 600px;
        font-family: 'Rubik-Medium';
        font-size: 18px;
        color: #ffffff;
        text-shadow: 0 0 10px #00182e;
      }
      .download{
        margin-left: -20px;
        a {
          display: inline-block;
          margin-bottom: 10px;
        }

        img {
          max-width: 165px;
          width: 100%;
          margin: 20px;
          @media only screen and (min-width: 450px) {
            -o-transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
            transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
              &:hover{
              -webkit-transform: scale(1.08);
              -ms-transform: scale(1.08);
              transform: scale(1.08);
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1500px) {
      top: 100px;
      .detail{
        .slogan{
          max-width: 450px;
        }
        p{
          font-size: 16px;
        }
        .download{
          img{
            max-width: 150px;
          }
        }
      }
    }
    @media only screen and (max-width: 980px) {
      top: 80px;
      left: 50%;
      transform: translateX(-50%);

      .detail{
        margin: 20px 50px;
        p{
          font-size: 14px;
        }
        .download{
          margin-top: 360px;
          text-align: center;
          img {
            margin: 0 10px 0 10px;
          }
        }
      }
    }
  }
}
.section-02{
  position: relative;
  margin-top: -90px;
  padding: 0;
  height: 1000px;
  background-image: url('/static/img/home/bg2.png');
  background-position: center;
  z-index: 0;
  img{
    width:100%;
    height: 100%;
    margin-top:-40px;
    max-width: 1100px;
    max-height: 650px;
    &.scrollimgzoom{
      -webkit-animation-name: scaleDraw;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-duration: 1s;
    }
  }
  h1{
    font-size: 48px;
    font-weight: bold;
    margin-top: 200px;
  }
  p{
    font-family: 'Rubik-Regular';
    font-size: 20px;
    font-weight: 100;
    &.content-1{
      margin:0px;
      display: inline;
    }
    &.content{
      padding:0px;
      margin:0px;
      display: inline;
    }
  }
}
.section-03{
  height: 950px;
  margin-top:-80px;
  z-index:1;
  // overflow: hidden;
  img{
    &.underline{
      margin-top:-50px;
    }
    &.gif{
      width: 100%;
      max-width: 778px;
      max-height:693px;
      -webkit-animation-name: scaleDraw; /*關鍵幀名稱*/
      -webkit-animation-iteration-count: 1 ;  /*動畫播放的次數*/
      -webkit-animation-duration: 1s; /*動畫所花費的時間*/
    }
  }
  .detail{
    .titleName{
      font-size: 48px;
      font-weight: bold;
      margin-top: 50px;
      margin-bottom: 0px;
    }
    .subtitle{
      font-family: 'Rubik-Regular';
      font-size: 20px;
      font-weight: 100;
      margin-top: 0px;
    }
    .content-1{
      margin:0px;
      display: inline;
    }
    .content{
      padding:0px;
      margin:0px;
      display: inline;
    }
  }
}
.section-04{
  height: 950px;
  background-color:#006bce;
  background-image:url('/static/img/home/castle.png');
  background-position: center bottom;
  .detail{
    .titleName{
      font-size: 48px;
      font-weight: bold;
      margin-top: 50px;
      margin-bottom: 0px;
      color: #ffffff;
    }
    .subtitle{
      font-family: 'Rubik-Regular';
      font-size: 20px;
      font-weight: 100;
      margin-top: 0px;
      color: #ffffff;
    }
    .content-1{
      margin:0px;
      display: inline;
    }
    .content{
      padding:0px;
      margin:0px;
      display: inline;
    }
  }
  img{
    width: 100%;
    max-width:780px;
    max-height: 773px;
    margin-top: -100px;
     -webkit-animation-name: scaleDraw;
     -webkit-animation-iteration-count: 1 ;
     -webkit-animation-duration: 1s;
  }
}
.section-05{
  height: 950px;
  background-image:url('/static/img/home/bg3.png');
  background-position: center bottom;
  // overflow: hidden;
  img{
    &.clan{
      max-width:680px;
      max-height: 680px;
      width: 100%;
       -webkit-animation-name: scaleDraw;
       -webkit-animation-iteration-count: 1 ;
       -webkit-animation-duration: 1s;
    }
    &.underline{
      margin-top:-70px;
    }
  }
  .detail{
    .titleName{
      font-size: 48px;
      font-weight: bold;
      margin-top: 50px;
      margin-bottom: 0px;
      color: #000000;
    }
    .subtitle{
      font-family: 'Rubik-Regular';
      margin-top: 0px;
      font-size: 20px;
      font-weight: 100;
      color: #000000;
    }
    .content-1{
      margin:0px;
      display: inline;
    }
    .content{
      padding:0px;
      margin:0px;
      display: inline;
    }
  }
}
.section-06{
  height: 1600px;
  img{
    width: 100%;
    &.multi{
      height: 100%;
      margin-top: 60px;
      max-width: 1000px;
      max-height: 654px;
       -webkit-animation-name: scaleDraw;
       -webkit-animation-iteration-count: 1 ;
       -webkit-animation-duration: 1s;
    }
  }
  .detail{
    .titleName{
      font-size: 48px;
      font-weight: bold;
      margin-top: 50px;
      margin-bottom: 0px;
      color: #000000;
    }
    .subtitle{
      font-family: 'Rubik-Regular';
      margin-top: 0px;
      font-size: 20px;
      font-weight: 100;
      color: #000000;
    }
    .content-1{
      margin:0px;
      display: inline;
    }
    .content{
      padding:0px;
      margin:0px;
      display: inline;
    }
  }
  .carouse-title{
    font-size: 48px;
    font-weight: bold;
    color: #000000;
  }
  .carousel{
    max-width: 1100px;
    margin: 0 auto;
    .slide{
      background-color:transparent;
      border-radius:0px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .section-01{
    .bg-block{
      img{
        &.bg1{
          height: 550px;
        }
      }
    }
  }
  .section-03{
    overflow: hidden;
  }
  .section-05{
    overflow: hidden;
  }
  .section-04{
    img{
      margin-top:-65px;
    }
  }
}
@media only screen and (max-width: 450px) {
  .section-01{
    .bg-block{
      img{
        &.bg1{
          height:520px;
        }
        &.cloud{
          z-index:1;
          left: 11px;
          bottom: 11px;
        }
      }
    }
    .content{
      .detail{
        p{
          font-size: 12px;
        }
        .download{
          width:350px;
          margin-left:10px;
          img{
            max-width: 150px;
          }
        }
      }
    }
  }
  .section-02{
    height: 600px;
    margin-top: 180px;
    background-size: cover;
    img{
      display: flex;
      justify-content: center;
      margin-top: 30px;
      min-width: 420px;
    }
    h1{
      font-size: 26px;
      margin-top: 100px;
    }
    p{
      font-size: 16px;
    }
  }
  .section-03{
    height:600px;
    overflow: hidden;
    img{
      &.underline{
        margin-top: -100px;
      }
    }
  }
  .section-04{
    height: 670px;
    background-size: 600px 250px;
    img{
      margin-top:-40px;
    }
    .detail{
      .titleName{
        margin-top: 70px;
      }
    }
  }
  .section-05{
    height: 650px;
    background-size: cover;
    img{
      &.bottom{
        margin-top:-80px;
      }
    }
  }
  .section-06{
    height: 1200px;
    img{
      &.multi{
        min-width: 430px;
      }
    }
    .carouse-title{
      font-size: 28px;
      margin-top: 80px;
    }
    .carousel{
      margin-top: 0px;
    }
  }
  .section-03,
  .section-04,
  .section-05,
  .section-06{
    .detail{
      .titleName{
        font-size: 28px;
      }
      .subtitle{
        font-size: 16px;
      }
    }
  }
}
</style>
<style lang="scss">
.section-06{
  .carousel{
    max-width: 1000px !important;
    .carousel-3d-container{
      .carousel-3d-controls{
        .pre:hover{
          opacity: 0.5;
        }
        .next:hover{
          opacity: 0.5;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .section-06{
    .carousel{
      width: 80%;
      .carousel-3d-container{
        .carousel-3d-controls{
          display:none;
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .section-06{
    .carousel{
      width: 75%;
      .carousel-3d-container{
        .carousel-3d-slider{
          .right-1{
            transform: translateX(200px) translateZ(-400px) rotateY(0deg)!important;
          }
          .left-1{
            transform: translateX(-200px) translateZ(-400px) rotateY(0deg)!important;
          }
        }
      }
    }
  }
}
</style>
