<template>
  <div class="header">
    <v-app-bar
      color="#006dd2"
      height="65px"
      dark
      class="text-center"
    >
      <v-container>
        <v-row class="ma-0">
          <v-col cols="2" align-self="center" class="row reverse justify-center d-flex bp400">
            <a class="pt-2" href="/"><img src="/static/img/common/LOGO.png" class="logo" width="100%"></a>
          </v-col>
          <!-- <v-col cols="8" align-self="end" class ="tabsList hidden-sm-and-down"> 

          </v-col> -->
        </v-row>
      </v-container>
    </v-app-bar>
    <!-- <div class="headerUnderline"></div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      clipped: false,
      drawer: false,
      dialog: false,
      tabsName: [
        { title: 'Home', to: '/home' },
        { title: 'characters', to: '/characters' },
        { title: 'News', to: '/promotion' },
        { title: 'Tools', to: '/blog' },
        { title: 'tournaments', to: '/blog2' }
      ],
      languages: [
        { title: 'English', content: 'en' },
        { title: 'Japan', content: 'jp' },
        { title: 'Spanish', content: 'es' },
        { title: 'Russian', content: 'ru' },
        { title: 'Português', content: 'pt' }
      ],
      miniVariant: false
    }
  },
  computed: {
    // language () {
    //   return this.$i18n.locale
    // },
    // showFloatAgree () {
    //   return this.$store.state.isCookie
    // },
    // showLanguageOption () {
    //   return !this.$store.state.languageOption
    // }
  },
  created () {
    // if (!process.server) {
    //   this.$store.commit('initCookiePolicy')
    //   this.$store.commit('initLanguageOption')
    // }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.bp400 {
  max-width: 150px;
  .logo {
    margin-left: 20px;
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
}
.headerUnderline{
  background-color: #359ffd;
  width: 100%;
  height: 5px;
}
@media only screen and (max-width: 1300px) {

}
@media only screen and (max-width: 1150px) {
  .bp400 {
    min-width: 120px;
  }

}
@media only screen and (max-width: 500px) {
  .bp400 {
    padding-left: 0px!important;
    padding-right: 0px!important;
    min-width: 120px;
    .logo {
      margin-left: 20px;
      width: 100%;
    }
  }

}
</style>
<style lang="scss">
.v-toolbar{
  box-shadow: none !important;
}
</style>

