import Vue from 'vue'
import VueI18n from 'vue-i18n'
import scLocale from './sc'
import tcLocale from './tc'
import enLocale from './en'
import idLocale from './id'
import itLocale from './it'
import ruLocale from './ru'
import viLocale from './vi'
import ptLocale from './pt'
import esLocale from './es'
import deLocale from './de'
import frLocale from './fr'

Vue.use(VueI18n)

const messages = {
  sc: {
    ...scLocale
  },
  tc: {
    ...tcLocale
  },
  en: {
    ...enLocale
  },
  id: {
    ...idLocale
  },
  it: {
    ...itLocale
  },
  ru: {
    ...ruLocale
  },
  vi: {
    ...viLocale
  },
  pt: {
    ...ptLocale
  },
  es: {
    ...esLocale
  },
  de: {
    ...deLocale
  },
  fr: {
    ...frLocale
  }
}

const getUrlParam = function (paraName) {
  const url = document.location.search
  const arrObj = url.split('?')
  if (arrObj.length > 1) {
    const arrPara = arrObj[1].split('&')
    let arr

    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=')

      if (arr !== null && arr[0] === paraName) {
        return arr[1]
      }
    }
    return ''
  } else {
    return ''
  }
}

const queryLang = getUrlParam('lang')

const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: queryLang || 'en',
  messages
})

export default i18n
