<template>
  <v-footer dark padless color="#006ed2" class="footer">
      <v-container>
        <v-row no-gutters class="mb-4">
          <v-col cols="12">
            <p class="follow-text white--text">Follow us on</p>
            <div class="follow">
              <div class="mediaLogo">
                <a href="https://www.facebook.com/PokerGlory" target="_blank">
                <img class="fb mr-sm-8 mr-6" src="/static/img/common/fb.png" /></a>
                <a href="https://www.youtube.com/channel/UCl1_Uz9lVyARePmMzbpgugw" target="_blank">
                <img class="yt" src="/static/img/common/youtube.png" /></a>
              </div>
              <div class="downloadIcon">
                <a href="https://play.google.com/store/apps/details?id=com.nuts.pokerglory.android" target="_blank"><img @click="googleDownload()" class="google" src="/static/img/common/google.png" /></a>
                <a href="https://apps.apple.com/us/app/poker-glory-texas-holdem/id1570964814" target="_blank"><img @click="appleDownload()" class="apple" src="/static/img/common/apple.png" /></a>
                <a :href="ANDROID_VERSON"><img @click="androidDownload()" class="android" src="/static/img/common/android.png" /></a>
              </div>
            </div>
            <div class="divider mb-8 white-text"></div>
          </v-col>
          <v-col class="mb-6 policy" cols="12">
            <div class="left">
              <p>
                <a class="pp" href="https://cdn.thepokerglory.com/legal/privacyPolicy.html" target="_blank">
                Privacy Policy
                </a> 
              </p>
              <p>
                <a class="ts" href="https://cdn.thepokerglory.com/legal/termsOfService.html" target="_blank">
                  Terms of service
                </a> 
              </p>
              <p style="white-space:nowrap;">
                Contact us by email: cs@thepokerglory.com
              </p>
            </div>
            <div class="right">
              <a href="/"><img class="logo" src="/static/img/common/LOGO.png"/></a>
            </div>
          </v-col>
          <v-col class="copyright" cols="12">
            <strong>
              Copyright © 2019-{{ new Date().getFullYear() }} PokerGlory. All Rights
              Reserved.</strong>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
</template>  
<script>
export default {
  data () {
    return {
      clipped: false,
      links: [
        {
          title: 'PrivacyPolicy',
          href: '/PrivacyPolicy'
        },
        {
          title: 'Terms of service',
          href: '/TermsofService'
        },
        {
          title: 'Contact us by email: cs@thepokerglory.com',
          // href: '/TermsofService'
        }
      ],
      ANDROID_VERSON: ''
    }
  },
  created () {
    this.ANDROID_VERSON = ANDROID_VERSON;
  },
  methods:{
    googleDownload(){
      this.$ga.event({
        eventCategory: 'Downloads',
        eventAction: 'download',
        eventLabel: 'google'
      })
    },
    appleDownload(){
      this.$ga.event({
        eventCategory: 'Downloads',
        eventAction: 'download',
        eventLabel: 'apple'
      })
    },
    androidDownload(){
      this.$ga.event({
        eventCategory: 'Downloads',
        eventAction: 'download',
        eventLabel: 'android'
      })
    }
  }
}
</script>
<style lang="scss">
.container{
  max-width: 1200px;
}
.footer {
  height: 500px;
  .follow-text {
    font-size: 16px;
    font-family: 'Rubik-Regular' !important;
  }
  .follow{
    display: flex;
    justify-content: space-between;
   
    img{
      margin: 0 20px 20px 0;
      
    }
    .mediaLogo{
      float: left;
      img{
        height: 38px;
        @media only screen and (min-width: 450px) {
          &.fb:hover{
            width: 38px; 
            content:url('/static/img/common/fb2.png')
          }
          &.yt:hover{
            width: 55px; 
            content:url('/static/img/common/youtube2.png')
          }
        }
      }
    }
    .downloadIcon{
      float: right;
      img{
        max-width: 162px;
        @media only screen and (min-width: 450px) {
          -o-transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
          transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
            &:hover{
            -webkit-transform: scale(1.08);
            -ms-transform: scale(1.08);
            transform: scale(1.08);
          }
        }
        &.android{
          margin-right: 0;
        }
      }
    }
  }
  .divider{
    background-color: #68b6ff;
    width: 100%;
    height: 1px;
  }
  .policy{
    width: 800px;
    display: flex;
    justify-content: space-between;
    position: relative; 
    .left{
      float: left;
      p {
        font-family: 'Rubik-Regular';
        font-size: 16px;
        a{
          font-family: 'Rubik-Regular';
          font-size: 16px;
          color:white; 
          font-weight:100;
          @media only screen and (max-width: 450px) {
            &.pp:hover{
              color: white !important; 
            }
            &.ts:hover{
              color: #ffffff !important; 
            }
          }
          &.pp:hover{
            color: #5cb1ff; 
          }
          &.ts:hover{
            color: #5cb1ff; 
          }
        }
      }
    }
    .right{
      position: absolute; 
      top: 0 ; 
      right: 0;
      img{
        width: 140px;
      }
    }
  }
  .copyright{
    strong{
      font-family: 'Rubik-Regular';
      font-size: 14px;
      color:#7bbdfd; 
      font-weight:100;
    }
  }
}

@media only screen and (max-width: 767px){
  .footer{
    .follow{
      flex-direction: column;
      .mediaLogo{
        img{
          max-width: 50px;
          &.yt{
            max-width:55px;
            height: 50px;
          }
        }
      }
      .downloadIcon{
        img{
          max-width: 160px;
        }
      }
    }
  }
}
@media only screen and (max-width: 450px){
  .footer{
    .follow{
      .mediaLogo{
        img{
          max-width: 45px;
          height:40px;
          &.yt{
            max-width:55px;
            height: 40px;
          }
        }
      }
      .downloadIcon{
        img{
          max-width: 29%;
        }
      }
    }
    .policy{
      .right{
        img{
          width: 100px;
        }
      }
    }
  }
}
</style>
  