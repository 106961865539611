class CocosUtil {
  platform = []
  constructor () {
    this.getPlatform = this.getPlatform.bind(this)
  }

  init () {
    window['callH5'] = this.callH5.bind(this)
    this.sendMsg({ type: 'init' })
  }
  callH5 (str) {
    let data = JSON.parse(str)
    this.platform = data.platforms.split('|')
  }
  getPlatform () {
    return this.platform
  }
  sendMsg (msg) {
    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
      let webkit = window['webkit']
      if (webkit == null) {
        // alert('no webkit')
        return false
      }
      let str = JSON.stringify(msg)
      webkit.messageHandlers.callCocos.postMessage(str)
    } else if (navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1) {
      let bridge = window['Bridge']
      if (bridge == null) {
        // alert('Bridge is null')
        return false
      }

      let str = JSON.stringify(msg)
      bridge.callCocos(str)
    } else {
      if (msg.type === 'link') {
        window.open(msg.link)
      }
    }
  }
}

let cocosUtil = new CocosUtil()
export default cocosUtil
