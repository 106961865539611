import Vue from 'vue'
import Router from 'vue-router'
import Layout from './pages/layout.vue'
import Home from './pages/home.vue'
// import page404 from '@/components/404.vue'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: '/',
      component: Layout,
      redirect: { name: 'home' },
      children: [
        { path: 'home', name: 'home', component: Home, meta: { keepAlive: true, noresposive: true } },
        { path: 'share', name: 'share', component: () => import('@/pages/share.vue'), meta: { keepAlive: true, noresposive: true } },
      ]
    },
    // app端内账号注销页面
    { path: '/app/account', name: 'app-account', component: () => import('@/pages/app/account.vue'), meta: { keepAlive: true, noresposive: true } },
    // {
    //   path: '*',
    //   name: 'NoResult',
    //   component: page404
    // }
  ]
})

export default router
