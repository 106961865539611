<template>
  <div id="app">
    <router-view></router-view>
    <div class="topUp" v-if="show" @click="toTop">
      <div
        style="{
          height: 100%;
          width: 100%;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
        }"
      >
        <img src="/static/img/common/top.png" alt="" style="width: 100%;display:block;">
      </div>
    </div>
    <div id="fbIcon">
      <div id="arrow">
        <img src="/static/img/common/facebook.png"/>
        <iframe 
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPokerGlory%2F&tabs=timeline&width=280&height=300&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" 
          width="280" 
          height="300" 
          style="border:none;overflow:hidden" 
          scrolling="no" 
          frameborder="0" 
          allowfullscreen="true" 
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
        </iframe>
        <a target="_blank" 
          style="background-color:#f6f7f8;text-align:left;font-size:12px;color:gray; padding-left:10px;" 
          href="http://pic.sopili.net/lnk/-/pilipress/float-fb/">
        get this widget</a>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld'

export default {
  name: 'App',

  components: {
    // HelloWorld
  },
  data() {
    return {
      show: false
    }
  },
  watch: {
    $route (to, from) {
      // console.log(to)
      if (to.name === 'home') {
        document.querySelector('#arrow').style.display = 'block'
      }else {
        document.querySelector('#arrow').style.display = 'none'
      }
    }
  },
  methods: {
    toTop () {
      document
        .querySelector('body')
        .scrollIntoView({ block: 'start', behavior: 'smooth' })
      this.show = false
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      let scrollt = document.documentElement.scrollTop + document.body.scrollTop
      if (scrollt > 200) {
        this.show = true
      }
      if (scrollt === 0) {
        this.show = false
      }
    })

    function detectIE () {
      var ua = window.navigator.userAgent

      var msie = ua.indexOf('MSIE ')
      if (msie > 0) {
        return true
      }

      var trident = ua.indexOf('Trident/')
      if (trident > 0) {
        return true
      }

      var edge = ua.indexOf('Edge/')
      if (edge > 0) {
        return true
      }

      return false
    }

    if (!!window.ActiveXObject || 'ActiveXObject' in window || detectIE()) {
      window.addEventListener('hashchange', () => {
        let currentPath = window.location.hash.slice(1)
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath)
        }
      }, false)
    }
  }
}
</script>
<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
}
@media only screen and (max-width: 450px){
  #app {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    margin:0 auto; 
    padding: 0 !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

</style>
<style lang="scss" scoped>
#fbIcon{
  overflow: hidden;
}
#arrow {
  visibility: visible;
  width: 280px;
  height: 330px;
  position: fixed;
  top: 500px; 
  right: -281px;
  background-color: white;
  z-index: 2147483647;
  img{
    cursor: pointer;
    position: absolute;
    transform: scale(0.9);
    margin-left: -47px!important;
    top: -8px;
  }
}
#arrow:hover {
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  top: 500px;
  right: 0; 
  z-index:1500 !important;
}

.topUp{
  position:fixed;
  width:40px;
  height:40px;
  right:150px;
  bottom:100px;
  z-index:4;
  cursor:pointer;
  opacity: 1.8;
}
@media only screen and (max-width: 650px) {
  .topUp{
    right:10px;
  }
}
@media only screen and (max-width: 450px) {
  #arrow{
    top: 350px!important;
    img{
      margin-left: -36px!important;
      top: -6px;
      height:120px;
    }
  }
  #arrow:hover {
    top: 350px!important;
  }
  .topUp{
    display: none;
  }
}

</style>
